import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";
import ReactMarkdown from 'react-markdown'
import Moment from 'react-moment';
import Layout from '../components/Layout'
import Img from 'react-cloudinary-lazy-image';
import "../stylesheets/news.css";

const NewsFormTemplate = ({ data, pageContext }) => {

const { newsData } = data;
const newsItem = newsData.frontmatter;

var imgSegment = newsItem.photo?.split('/');
var imgFile;
var imgFolder;

if (imgSegment) {
    imgFile = imgSegment.pop();
    imgFolder = imgSegment.pop();
}

  return (
    <Layout pageContext={pageContext}>
      <div className="container mx-auto my-6">
        {/* Arrow to navigate back */}
        <div className="px-6 bg-gray md:px-12">
          <span className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="overflow-hidden w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path strokeLinecap="round" strokeLinejoin="" oin="round" d="M15 19l-7-7 7-7" />
            </svg>
            <Link to="/actualites" className="text-lg font-semibold uppercase" style={{'textDecoration': 'none'}} >
              Retour aux actualités
            </Link>
          </span>

          {/* Title */}
          <h3 className="mt-8 w-full font-bold tracking-wide uppercase ">{newsItem.titre}</h3>

          {/* Image with Content */}
          <div className="flex flex-col mt-8 space-y-8 lg:space-y-0 lg:space-x-8 lg:flex-row">
          <div className="d-none d-lg-block"  style={{width: '40%'}} >
          <img
            src={newsItem.photo}
            alt={newsItem.titre}
        />
        </div>
        <div className="d-xs-block d-md-none">
          <Img
            cloudName={'groupea'}
            imageName={imgFolder + '/' + imgFile}
            fluid={{
                maxWidth: 1280
            }}
            alt={newsItem.titre}
            quality="best"
        />
        </div>
         {/*  */}
            <div className="flex flex-col space-y-6 font-normal text-black lg:space-y-0 ">
              <div>
                <Moment format="DD.MM.YYYY">
                  {newsItem.datePublication}
                </Moment>
              </div>
              <div className="text-lg font-normal text-black prose">
             { newsItem.description && <ReactMarkdown children={newsItem.description.replace(/\\/gi, '  ')} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewsFormTemplate;

export const newsItemQuery = graphql`
   query news($id: String!) {
    newsData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        titre
        url
        position
        photo
        datePublication
        description      
      }
    }
  }
`

